<template>
    <div :class="`list-item ${type}`">
        <div :class="getClass(node)" @click="toggleChildren">
            <strong class="root label" v-if="root == 0">{{ node.name }}</strong>
            <strong class="label" v-else-if="hasChildren(node)">{{ node.name }}</strong>
            <span class="label" v-else>{{ node.name }}</span>

            <div class="ref">&nbsp;</div>
            <div class="amount">
                <span v-if="hasChildren(node) && !showChildren">{{ getClosing(node) | fixAccount }}</span>
                <span v-else-if="hasChildren(node) && showChildren"></span>
                <span v-else>{{getClosing(node) | fixAccount}}</span>
            </div>
        </div>
        <transition name="list">
            <div style="padding-left: 50px;" v-if="showChildren && hasChildren(node)">
                <template v-if="node.ledger_heads && node.ledger_heads.length">
                    <node v-for="(child) in node.ledger_heads" :node="child" :root="parseInt(root) + 1" type="items"
                          :key="`${child.id}-${root}`"></node>
                </template>
                <template v-if="node.deep_down && node.deep_down.length">
                    <node v-for="(child) in node.deep_down" :node="child" :root="parseInt(root) + 1" type="heads"
                          :key="child.id"></node>
                </template>
            </div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .list-item {
        div {
            .label {
                display: inline-block;
                width: calc(100% - 200px);
                text-transform: capitalize;
                float: left;
            }
            strong {
                text-decoration: underline;
                &.root {
                    text-transform: uppercase;
                    text-decoration-style: double;
                    text-decoration-line: underline;
                }
            }

            .ref {
                float: left;
                width: 53px;
                padding: 0px 10px;
                text-align: center;
            }

            .amount {
                float: left;
                width: 147px;
                padding: 0px 5px;
                text-align: right;
            }
        }
    }

    .has-children {
        cursor: pointer;
    }

    .list-enter-active,
    .list-leave-active,
    .list-move {
        transition: 300ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
        transition-property: opacity, transform;
    }

    .list-enter {
        opacity: 0;
        transform: translateX(50px) scaleY(0.5);
    }

    .list-enter-to {
        opacity: 1;
        transform: translateX(0) scaleY(1);
    }

    .list-leave {
        opacity: 0;
        transform: translateX(50px) scaleY(0.5);
    }

    .list-leave-to {
        opacity: 1;
        transform: translateX(0) scaleY(1);
    }
</style>
<script>
    export default {
        name: "node",
        props: {
            node: null,
            type: null,
            root: null,
        },
        data: () => ({
            showChildren: true
        }),
        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren
            },
            getClass(node) {
                if (this.hasChildren(node)) {
                    return `has-children level${this.root}`
                } else return `level${this.root}`
            },
            getClosing(node) {
                return node.balance - node.sum_cr + node.sum_dr
                // return node.balance
            },
            hasChildren: (node) => (node.deep_down && node.deep_down.length) || (node.ledger_heads && node.ledger_heads.length)
        },
        filters: {
            fixAccount(item) {
                if (!isNaN(item)) return item >= 0 ? item.numberFormat() : `${(-(item)).numberFormat()}`
            }
        }
    };
</script>