<template>
	<div class="tree">
		<nodeh :node="treeData" root="0" type="heads"></nodeh>
	</div>
</template>
<style lang="scss" scoped>
	.tree {
		position: relative;
		overflow: hidden;
		padding-left: 10px;
	}
</style>

<script>
    import Nodeh from "./NodeH";

    export default {
        props: {
            treeData: null
        },

        methods: {
            handleClick (node) {
                //this.$emit('node-click', node);
            },
        },

        mounted() {
            // console.log(this.treeData);
        },

        components: {
            Nodeh
        }
    };
</script>